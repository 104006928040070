<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
    <header v-else>
      <div v-if="navBackdrop" @click.prevent="hideNavigation" class="nav-backdrop"></div>
      <div :class="{ 'onScroll': !topOfPage}" class="container-fluid dektop-navigation">
        <div class="container">
          <nav>
            <div class="row">
              <div class="col-3">
                <router-link :to="`/${$i18n.locale}`"><img class="logo img-fluid" :src="this.header_logo.url" :alt="this.header_logo.alt"></router-link>
              </div>
              <div class="col-9 text-end">
                <a v-for="(item, index) in this.header_nav" :key="`${index}`" :href="item.header_link_url.url" class="dektop-navigation__link">{{ $prismic.richTextAsPlain(item.header_link_label) }}</a>
                <div class="dropdown">
                  <button class="btn dropdown-toggle" type="button" id="changeLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                    <img class="flag img-fluid" :src="require(`@/assets/images/shared/${this.$i18n.locale}.svg`)" alt="lang" />
                    <img class="arrow img-fluid" src="@/assets/images/shared/arrow_down_white.svg" alt="arrow" />
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="changeLanguage">
                    <li><button class="dropdown-item" type="button" @click.prevent="setLocale('en')"><img class="flag img-fluid" src="@/assets/images/shared/en.svg" alt="english" /> English</button></li>
                    <li><button class="dropdown-item" type="button" @click.prevent="setLocale('de')"><img class="flag img-fluid" src="@/assets/images/shared/de.svg" alt="deutsch" /> Deutsch</button></li>
                    <li><button class="dropdown-item" type="button" @click.prevent="setLocale('pl')"><img class="flag img-fluid" src="@/assets/images/shared/pl.svg" alt="polski" /> Polski</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div :class="{ 'onScroll': !topOfPage}" class="mobile-navbar">
        <span @click="hideNavigation"><router-link :to="`/${$i18n.locale}`"><img class="logo img-fluid" src="@/assets/images/shared/logo.png" alt="logo"></router-link></span>
        <div class="mobile-navbar__icons" @click.prevent='showNavigation'>
          <div class="nav-icon" :class="showMobileMenu ? 'open' : ''">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="mobile-naviagtion" :class="showMobileMenu ? 'mobile-menu-active' : ''">
        <div class="mobile-naviagtion__content">
          <span v-for="(item, index) in this.header_nav" :key="`${index}`" @click="hideNavigation"><a :href="item.header_link_url.url" class="mobile-naviagtion__link">{{ $prismic.richTextAsPlain(item.header_link_label) }}</a></span>
          <div class="dropdown">
            <button class="btn dropdown-toggle" type="button" id="changeLanguageMobile" data-bs-toggle="dropdown" aria-expanded="false">
              <img class="flag img-fluid" :src="require(`@/assets/images/shared/${this.$i18n.locale}.svg`)" alt="lang" />
              <img class="arrow img-fluid" src="@/assets/images/shared/arrow_down_black.svg" alt="arrow" />
            </button>
            <ul class="dropdown-menu" aria-labelledby="changeLanguageMobile">
              <li><span @click="hideNavigation"><button class="dropdown-item" type="button" @click.prevent="setLocale('en')"><img class="flag img-fluid" src="@/assets/images/shared/en.svg" alt="english" /> English</button></span></li>
              <li><span @click="hideNavigation"><button class="dropdown-item" type="button" @click.prevent="setLocale('de')"><img class="flag img-fluid" src="@/assets/images/shared/de.svg" alt="deutsch" /> Deutsch</button></span></li>
              <li><span @click="hideNavigation"><button class="dropdown-item" type="button" @click.prevent="setLocale('pl')"><img class="flag img-fluid" src="@/assets/images/shared/pl.svg" alt="deutsch" /> Polski</button></span></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data: () => {
    return {
      cmsContentPending: false,
      showMobileMenu: false,
      navBackdrop: false,
      topOfPage: true,
      header_logo: null,
      header_nav: null
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll)
  },
  created () {
    this.getHeaderContent()
  },
  methods: {
    async getHeaderContent () {
      await this.$prismic.client.getSingle('header', { lang: this.$i18n.lang })
        .then((document) => {
          // header section
          this.header_logo = document.data.header_logo
          this.header_nav = document.data.header_nav
          this.cmsContentPending = true
        })
    },
    setLocale (locale) {
      this.$i18n.locale = locale
      this.$router.push({
        params: { lang: locale }
      }).catch(() => {})
    },
    handleScroll () {
      if (window.pageYOffset > 100) {
        if (this.topOfPage) this.topOfPage = false
      } else {
        if (!this.topOfPage) this.topOfPage = true
      }
    },
    showNavigation () {
      this.showMobileMenu = !this.showMobileMenu
      this.navBackdrop = !this.navBackdrop
    },
    hideNavigation () {
      this.showMobileMenu = false
      this.navBackdrop = false
    }
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getHeaderContent()
    }
  }
}
</script>

<style lang="scss">
  @import "./HeaderComponent.scss";
</style>
